import { useAddPrivilegeItemToCart } from './use-add-privilege-item-to-cart'
import { useCartReferenceStore } from '@components/LoadCart'
import { useNotifications } from '@components/NotificationsProvider'
import { useRemovePrivilegeItemFromCartMutation } from '@generated'
import { useCart } from '@lib/hooks/useCart'
import { useTranslation } from 'next-i18next'

export function useRemovePrivilegeItemFromCart() {
  const cart = useCart()
  const cartReferenceStore = useCartReferenceStore()
  const reference = cartReferenceStore.reference as string
  const [, removePrivilegeItemFromCartMutation] = useRemovePrivilegeItemFromCartMutation()
  const { t } = useTranslation(['other', 'notifications'])
  const notifications = useNotifications()
  const { addPrivilegeItemToCart, status } = useAddPrivilegeItemToCart()

  async function removePrivilegeItemFromCart(itemId: string) {
    if (!cart) {
      throw new Error('Cart not found')
    }
    const item = cart?.privilegeItems?.find((item) => item?.id === itemId)
    try {
      await removePrivilegeItemFromCartMutation({
        input: { reference, privilegeCartItemId: itemId },
      })

      notifications.addNotification({
        variant: 'image-action-undo',
        label: item?.description || '',
        sublabel: 'Privilege',
        image: item?.imageSource || '',
        description: t('removedFromShoppingCart', { ns: 'other' }),
        actionLabel: t('undo', { ns: 'other' }),
        onClickAction: () =>
          // @ts-ignore
          addPrivilegeItemToCart(item?.privilegeId, item?.imageSource, item?.description),
      })
    } catch (error) {
      notifications.addNotification({
        variant: 'error',
        label: t('itemCouldNotBeRemovedFromShoppingCart', { ns: 'other' }),
      })
    }
  }

  return { removePrivilegeItemFromCart, status }
}
