// @ts-strict-ignore
import { Item } from './helpers/types'

export function dataLayerRemoveFromCart(item: Item) {
  if (!item) {
    return
  }

  if (window['dataLayer']) {
    window['dataLayer'].push({ ecommerce: null })

    window['dataLayer'].push({
      event: 'remove_from_cart',
      ecommerce: {
        items: [item],
      },
    })
  }
}
