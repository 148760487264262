// @ts-strict-ignore
import { useCart } from './useCart'
import { useLocalization } from '@components/Localization'
import { useNotifications } from '@components/NotificationsProvider'
import { useAddArticleToCartMutation } from '@generated'
import { dataLayerAddToCart } from '@lib/data-layer-social-ads/add-to-cart'
import { genericPushToTrackingLayer } from '@lib/data-layer-social-ads/generic-push-to-tracking-layer'
import { articlesToDataLayerItems } from '@lib/data-layer-social-ads/helpers/articlesToDataLayerItems'
import { imgix } from '@lib/imigx'
import pinterestTracking from '@lib/pinterest-tracking'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'

type Status = 'loading' | 'idle' | 'disabled'
type AddToCartProps = {
  articleId: string
  quantity?: number
  message?: string
  size?: string
}

export function useAddArticleToCart(handleShowQuickCart?: () => void) {
  const { t } = useTranslation('notifications')
  const { language, parseGraphQlError } = useLocalization()
  const [status, setStatus] = useState<Status>('disabled')
  const [, addArticleToCart] = useAddArticleToCartMutation()
  const notifications = useNotifications()
  const cart = useCart()
  const router = useRouter()

  useEffect(() => {
    if (cart && cart.id && status === 'disabled') {
      setStatus('idle')
    }
  }, [cart, status])

  const handleAddArticleToCart = async ({
    articleId,
    quantity = 1,
    size,
    message,
  }: AddToCartProps) => {
    try {
      setStatus('loading')
      const { data, error } = await addArticleToCart({
        reference: cart.id,
        articleId,
        quantity,
        message,
        size,
      })
      if (error) {
        setStatus('idle')
        return notifications.addNotification({
          variant: 'error',
          label: parseGraphQlError(error.message),
        })
      }

      if (data) {
        const article = data.addArticleToCart.items?.find((item) => item.article.id === articleId)
        const prefix = `/${language}`
        const dataLayerItem = articlesToDataLayerItems([article] as any)
        dataLayerAddToCart(dataLayerItem[0])
        genericPushToTrackingLayer('Ecommerce', 'addToCart')
        pinterestTracking.addToCart({
          product_id: articleId,
          product_quantity: 1,
        })
        if (window.innerWidth <= 640 || !handleShowQuickCart) {
          notifications.addNotification({
            variant: 'image-action',
            label: article.article.brand,
            sublabel: article.article.description,
            image: imgix(article.article.images[0], { width: 180 }),
            description: t('addedToShoppingBag', { ns: 'other' }),
            actionLabel: t('view', { ns: 'other' }),
            onClickAction: () =>
              router.push(
                `/[...slug]`,
                `${prefix}${t('secondaryNav.cart.url', { ns: 'navigation' })}`,
              ),
          })
        } else {
          handleShowQuickCart()
        }
        setStatus('idle')
      }
    } catch (error) {
      console.error(error)
      setStatus('idle')
      notifications.addNotification({
        variant: 'error',
        label: t('refreshAndTryAgain'),
      })
    }
  }

  return {
    handleAddArticleToCart,
    status,
  }
}
