import { useCartReferenceStore } from '@components/LoadCart'
import { useLocalization } from '@components/Localization'
import { useNotifications } from '@components/NotificationsProvider'
import { useAddPrivilegeToCartMutation } from '@generated'
import { useCart } from '@lib/hooks/useCart'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'

export function useAddPrivilegeItemToCart() {
  const cart = useCart()
  const cartReferenceStore = useCartReferenceStore()
  const reference = cartReferenceStore.reference as string
  const { push } = useRouter()
  const {
    t,
    i18n: { language },
  } = useTranslation(['other', 'notifications'])

  const { addNotification } = useNotifications()
  const [{ fetching }, addPrivilegeItemToCartMutation] = useAddPrivilegeToCartMutation()
  const { parseGraphQlError } = useLocalization()

  async function addPrivilegeItemToCart(id: string, imageSrc: string, description?: string) {
    if (!cart) {
      throw new Error('Cart not found')
    }

    const { data, error } = await addPrivilegeItemToCartMutation({
      input: {
        privilegeId: id,
        imageSource: imageSrc,
        reference,
      },
    })

    if (error) {
      addNotification({
        variant: 'error',
        label: parseGraphQlError(error.message),
      })
    }

    if (data && data.addPrivilegeToCart) {
      const prefix = `/${language}`
      addNotification({
        variant: 'image-action',
        label: description || '',
        sublabel: 'Privilege',
        image: imageSrc,
        description: t('addedToShoppingBag', { ns: 'other' }),
        actionLabel: t('toCheckout', { ns: 'other' }),
        onClickAction: () => push(`${prefix}${t('checkout.index', { ns: 'navigation' })}`),
      })
    }
  }

  return { addPrivilegeItemToCart, status: fetching ? 'loading' : 'idle' }
}
