import { useCartReferenceStore } from '@components/LoadCart'
import { useNotifications } from '@components/NotificationsProvider'
import { useRemoveItemFromCartMutation } from '@generated'
import { genericPushToTrackingLayer } from '@lib/data-layer-social-ads/generic-push-to-tracking-layer'
import { dataLayerRemoveFromCart } from '@lib/data-layer-social-ads/remove-from-cart'
import { useAddArticleToCart } from '@lib/hooks/use-add-article-to-cart'
import { useCart } from '@lib/hooks/useCart'
import { imgix } from '@lib/imigx'
import { useTranslation } from 'next-i18next'

export function useRemoveItemFromCart() {
  const cart = useCart()
  const cartReferenceStore = useCartReferenceStore()
  const reference = cartReferenceStore.reference as string
  const [, removeItemFromCartMutation] = useRemoveItemFromCartMutation()
  const { t } = useTranslation(['other', 'notifications'])
  const { addNotification } = useNotifications()
  const { handleAddArticleToCart, status } = useAddArticleToCart()

  async function removeItemFromCart(itemId: string, onReAddToCart?: () => void) {
    if (!cart) {
      throw new Error('Cart not found')
    }
    const item = cart.items?.find((item) => item?.id === itemId)
    try {
      await removeItemFromCartMutation({ reference, cartItemId: itemId })
      const firstImage = item?.article?.images?.length ? item.article.images[0] : undefined
      const articleType = item?.article?.articleType ?? undefined

      addNotification({
        variant: 'image-action-undo',
        label: item?.article?.brand || '',
        sublabel: item?.article?.description || '',
        image: firstImage ? imgix(firstImage as string, { width: 180 }) : '',
        description: t('removedFromShoppingCart', { ns: 'other' }),
        actionLabel: t('undo', { ns: 'other' }),
        onClickAction: async () => {
          await handleAddArticleToCart({
            articleId: item?.article?.id || '',
            quantity: item?.quantity || 1,
            message: item?.message || '',
          })
          onReAddToCart && onReAddToCart()
        },
      })

      dataLayerRemoveFromCart({
        item_id: item?.article?.id || '',
        item_name: item?.article?.description || '',
        currency: 'EUR',
        index: 0,
        item_brand: item?.article?.brand || '',
        item_category: 'Apparel & Accessories',
        item_category2: 'Jewelry',
        item_category3: articleType
          ? ['Horloge', 'Watch'].includes(articleType)
            ? 'Watches'
            : undefined
          : undefined,
        item_list_id: 'related_products',
        item_list_name: 'Related Products',
        price:
          // @ts-ignore
          Math.round((item?.article?.salePrice / 121 || item?.article?.price / 121) * 100) / 100,
        quantity: item?.quantity || 1,
      })
      genericPushToTrackingLayer('Ecommerce', 'removeFromCart')
    } catch (error) {
      console.error(error)
      addNotification({
        variant: 'error',
        label: t('refreshAndTryAgain', { ns: 'notifications' }),
      })
    }
  }

  return { removeItemFromCart, status }
}
