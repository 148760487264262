export const articleIdsWithoutSizeDropdown = [
  '921004566',
  '9212610202',
  '9212610203',
  '9212610204',
  '9212610205',
  '9212610206',
  '921004221',
  '9212610109',
  '9212610104',
  '9212610105',
  '921004225',
  '921005531',
  '9217863',
  '921005526',
  '921004220',
  '921003042',
  '921005527',
  '921005521',
  '9212620503',
  '921004217',
  '921005520',
  '9212616204',
  '9212612307',
  '9212614203',
  '9212614205',
  '9212614209',
  '921005528',
  '9212612305',
  '9212620505',
  '921004222',
  '921003740',
  '921003741',
  '921003742',
  '921003743',
  '921003040',
  '9212626103',
  '9212614103',
  '9212624207',
  '9218616',
]
